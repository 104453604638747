import { Link } from 'react-router-dom'
import s from './Footer.module.css'
import { useState } from 'react'

const Footer = () => {
    const [codusText, SetCodusText] = useState('Codus')

    return (
        <div className={s.Footer}>
            <div className={s.Footer_area}>
                <div className={s.Footer_area_part}>
                    <div className={s.Footer_area_part_logo}>
                        <Link to="/"> <div className={s.header_logo}>
                            <div className={s.header_logo_text}><span className={s.header_menu_item_sharp}>{'{'}</span>{codusText}<span className={s.header_menu_item_sharp}>{'}'}</span></div>
                        </div> </Link>
                    </div>

                    <div className={s.Footer_area_part_desc}>
                        <span className={s.goldColor}> Разработка </span> и  <span className={s.goldColor}>поддержка</span> веб-сайтов
                    </div>
                </div>

                <div className={s.Footer_area_part}>
                    
                    <Link to="/contacts"><div className={s.btnFreeConslt}>Связаться с нами</div></Link>
                </div>
            </div>

            <div className={s.Footer_Copyright}>© Copyright 2024</div>
        </div>
    )
}

export default Footer