import s from './FirstScreenImage.module.css'
import bg from '../../../../images/bg.png'
import Projects from "../../HomePage/Projects/Projects"
import { Link } from "react-router-dom";
import { useState, useRef } from 'react';
import Marquee from "react-fast-marquee";
import Cards from '../../../Cards/Cards';
import { Helmet } from 'react-helmet';


const FirstScreenImage = () => {

    const uslugiRef = useRef(null)


    const [clickItem, setClickItem] = useState('UX/UI Дизайн')

    const handleClickSetItem = (item, ref) => {
        setClickItem(item)
        if (ref) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }

    }

    const returnText = (item) => {
        switch (item) {
            case 'UX/UI Дизайн': {
                return 'Мы создаем интуитивно понятные и эстетически привлекательные интерфейсы, которые обеспечивают отличный пользовательский опыт. Наши дизайны не только выглядят хорошо, но и работают эффективно, помогая вашему бизнесу достичь своих целей и удовлетворить потребности клиентов.'
            }
            case 'Разработка': {
                return 'Наши сайты разрабатываются с использованием самых современных технологий. Ни один бизнес в современном мире не может обойтись без сайта. Иметь хороший, качественный и привлекательный сайт это в первую очередь престиж компании.'
            }
            case 'Реклама, SEO': {
                return 'Продвижение сайта - еще одна важная часть в развитии своего бизнеса. Хорошее SEO сайта в первую очередь увеличивает доверие поисковых систем к продукту, а в комплексе с настроенной рекламой увеличивает посещение сайта и рекомендации потенциальным клиентам.'
            }
            case 'SMM': {
                return 'Без социальных сетей никуда. Это базовый элемент любого бизнеса, который без сложных структур сайтов может правильно предложить, зарекомендовать новую услугу, прорекламировать акцию или довести информацию через красочные баннеры и привлекательно оформленые текста.'
            }
        }

    }

    const marqueeItems = ['таргет', 'разработка', 'контекст', 'реклама', 'социальные сети', 'сопровождение сайтов', 'презентации', 'дизайн', 'боты', 'SEO', 'тестирование', 'ускорение сайтов', 'SMM', 'оптимизация сайтов', 'доработка сайтов', 'переработка сайтов', 'адаптация', 'анализ', 'анализ конкурентов', 'вывод в топ', 'поднятие в поиске', 'ведение социальных сетей', 'Wordpress', 'React', 'NodeJS', 'Figma', 'Беларусь', 'Россия', 'исследования', 'прототипирование', 'анализ аудитории'];

    return (
        <>
            <Helmet>
                <title>Codus | Создаем сайты на заказ. Современные сайты с дизайнерским подходом</title>
                <meta name="description" content="Работаем на территории Беларуси и России. Создаем конкурентноспособные сайты с максимальной отдачей вашему бизнесу. Ускоряем и оптимизируем сайты, предлагаем улучшения для ваших сайтов и создаем новые решения!" />
                {/* Другие мета-теги */}
            </Helmet>


            <div className={s.firstScreen}>
                {/* <div className={s.imageArea}><img src={bg} alt="" /></div> */}
                <div className={s.textArea}>
                    <div className={s.leftPartArea}>
                        <h1>Создаем цифровые решения, <span className={s.blackColor}> которые работают</span></h1>
                        <h3>Профессиональная разработка и поддержка сайтов. Индивидуальный подход и высокое качество.</h3>


                        {/* <Link to="/contacts"><div className={s.btnFreeConslt}>Заказать бесплатную консультацию</div></Link> */}
                    </div>
                    {/* <div className={s.rightPartArea}>
                        <div className={s.itemSelector} onClick={()=>{handleClickSetItem('UX/UI Дизайн', uslugiRef)}}>UX/UI Дизайн</div>
                        <div className={s.itemSelector} onClick={()=>{handleClickSetItem('Разработка', uslugiRef)}}>Разработка</div>
                        <div className={s.itemSelector} onClick={()=>{handleClickSetItem('Реклама, SEO', uslugiRef)}}>Реклама, SEO</div>
                        <div className={s.itemSelector} onClick={()=>{handleClickSetItem('SMM', uslugiRef)}}>SMM</div>
                    </div>
                    <Link to="/contacts"><div className={s.btnFreeConsltMob}>Заказать бесплатную консультацию</div></Link> */}
                </div>

            </div>
            <div className={s.marqueeLine}>
                <Marquee autoFill="true" pauseOnHover="true" className='marquee' speed="50">
                    {marqueeItems.map((item, index) => (

                        <div key={index} className={`${s.marquueItem} ${(item == 'разработка') || (item == 'дизайн') || (item == 'сопровождение сайтов') || (item == 'реклама') || (item == 'SEO') || (item == 'SMM') || (item == 'переработка сайтов') || (item == 'анализ') || (item == 'Беларусь') || (item == 'Россия') ? s.pinkColor : ''}`}>
                            {item}
                            <span style={{ color: 'white', marginLeft: 45 }}>#</span>
                        </div>

                    ))}
                </Marquee>

            </div>



            <div className={s.secondScreen}>

                <div className={s.areaCards}>
                    <Cards name="Разработка сайта" price="от 30 000 ₽" desc={['Исследование конкурентов', 'Уникальный дизайн', 'CMS (WP или самописный)', 'Подключение метрик', 'Базовая SEO-Оптимизация', 'Проведение тестирования', 'Помощь в регистрации домена', 'Размещение на хостинге']} />
                    <Cards name="Поддержка" price="от 12 000 ₽" desc={['Проверка работоспособности и безопасности 24/7', 'Оперативное восстановление сайта в случае сбоев', 'Увеличение скорости загрузки сайта', 'Ежемесячное техническое сопровождение сайта', 'Написание и публикация новостей', 'Добавление новых страниц', 'Мониторинг ниши и предложения по актуализации сайта']} />
                    <Cards name="Реклама и продвижение" price="от 10 000 ₽" desc={['Настройка контекстной рекламы в поисковых системах', 'Настройка таргетированной рекламы', 'SEO-Продвижение', 'Наращивание ссылочной массы']} mark="Рекламный бюджет не входит в стоимость и оплачивается отдельно" />
                </div>

                
            </div>

            <div className={s.thirdScreen} ref={uslugiRef}>
                <h2><span className={s.pinkColor}>/</span><span>услуги</span></h2>

                <div className={s.areaThirditems}>
                    <div className={s.leftPartThird}>
                        <div className={`${s.itemThird} ${clickItem === 'UX/UI Дизайн' ? s.pinkColor : ''}`} onClick={() => { handleClickSetItem('UX/UI Дизайн') }}>UX/UI Дизайн</div>
                        <div className={`${s.itemThird} ${clickItem === 'Разработка' ? s.pinkColor : ''}`} onClick={() => { handleClickSetItem('Разработка') }}>Разработка</div>
                        <div className={`${s.itemThird} ${clickItem === 'Реклама, SEO' ? s.pinkColor : ''}`} onClick={() => { handleClickSetItem('Реклама, SEO') }}>Реклама, SEO</div>
                        <div className={`${s.itemThird} ${clickItem === 'SMM' ? s.pinkColor : ''}`} onClick={() => { handleClickSetItem('SMM') }}>SMM</div>
                    </div>
                    <div className={s.rightPartThird}>
                        <div className={s.topBlock}><span></span></div>
                        <div className={s.tableText}>
                            <span className={s.simpleText}>
                                <p>{returnText(clickItem)}</p>
                            </span>
                        </div>
                        <Link to="/contacts"><div className={s.btnFreeConslt}>Связаться с нами</div></Link>
                    </div>
                </div>
            </div>

            <Projects />

            <div className={s.fourthScreen}>
                <h2><span className={s.pinkColor}>/</span><span>процесс работы</span></h2>

                <div className={s.areaBlocks}>
                    <div className={s.itemBlockFourth}>
                        <div className={s.topBlock}><span>#1 Предпроектное исследование</span></div>
                        <div className={s.tableText}>
                            <span className={s.simpleText}>
                                <p>Аудитория, конкуренты, интеграция</p>
                            </span>
                        </div>
                    </div>

                    <div className={s.itemBlockFourth}>
                        <div className={s.topBlock}><span>#2 Проектирование</span></div>
                        <div className={s.tableText}>
                            <span className={s.simpleText}>
                                <p>Создаём прототипы интерфейсов, продумываем структуру каталога, прорабатываем интеграции</p>
                            </span>
                        </div>
                    </div>

                    <div className={s.itemBlockFourth}>
                        <div className={s.topBlock}><span>#3 Дизайн</span></div>
                        <div className={s.tableText}>
                            <span className={s.simpleText}>
                                <p>Рисуем все необходимые страницы и интерфейсы в соответствии с Вашим фирменным стилем</p>
                            </span>
                        </div>
                    </div>

                    <div className={s.itemBlockFourth}>
                        <div className={s.topBlock}><span>#4 Разработка</span></div>
                        <div className={s.tableText}>
                            <span className={s.simpleText}>
                                <p>Адаптивная вёрстка, программирование</p>
                            </span>
                        </div>
                    </div>

                    <div className={s.itemBlockFourth}>
                        <div className={s.topBlock}><span>#5 Тестирование</span></div>
                        <div className={s.tableText}>
                            <span className={s.simpleText}>
                                <p>Проверка, наполнение контентом, настройка аналитики, обучение</p>
                            </span>
                        </div>
                    </div>

                    <div className={s.itemBlockFourth}>
                        <div className={s.topBlock}><span>#6 Запуск</span></div>
                        <div className={s.tableText}>
                            <span className={s.simpleText}>
                                <p>Ваш сайт готов продавать</p>
                            </span>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
export default FirstScreenImage