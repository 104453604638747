import s from './About.module.css'
import hacker from './../../../../images/tempPhoto.png'

const About = () => {
    return (
        <div className={s.About}>
            <div className={s.About_Top}>
                <div className={s.About_Top_Text}>
                    <span className={s.pinkColor}>/</span>о нас
                </div>
                <div className={s.About_Top_Line}></div>
            </div>
            <div className={s.About_Cards}>
                <div className={s.About_Cards_Area}>
                    <div className={s.About_Cards_Image_title}>
                        <div className={s.About_Cards_Area_Base_text}>
                            
                            <div className={s.About_Cards_Base_Title}>
                                <div className={s.About_Cards_Area_Name}>
                                    CODUS
                                </div>
                                <div className={s.About_Cards_Area_Stack}>
                                {'> разработка сайтов'}
                                </div>

                            </div>


                            <p className={s.About_Cards_Area_p}>
                               Мы команда профессионалов в области разработки цифровых продуктов. Мы создаем красивые, современные, а самое главное продающие сайты, которые не только привлекают клиентов, но и заставляют их возвращаться назад. Мы тчательно подходим к изучению и анализу ниши для которой разрабатываем сайт. Продумываем структуру сайта на основании потребностей рынка и клиента, предлагаем собственные решения для увеличения конверсии и вовлеченности пользователей. Наша задача сделать ваш бизнес не только заметнее в интернете, но и прибыльнее.
                            </p>
                        </div>


                    </div>

                </div>

                
            </div>
        </div>
    )
}

export default About