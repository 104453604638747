import s from './InfoUsers.module.css'
import hackerPhoto from './../../../../images/tempPhoto.png'
import points from './../../../../images/points.png'
import points2 from './../../../../images/points2.png'
import { Link } from 'react-router-dom'

const InfoUsers = () => {
    return (
        <div className={s.InfoUsers}>
            <div className={s.InfoUsers_areaInCard}>
                <div className={s.InfoUsers_leftPart}>
                    <div className={s.InfoUsers_leftPart_title}>
                        Наши основатели это
                        <span className={s.pinkColorText}> UI/UX дизайнер</span> и <span className={s.pinkColorText}>full-stack разработчик</span>
                    </div>

                    <div className={s.InfoUsers_leftPart_mainText}>
                        Codus – это команда профессионалов, которая специализируется на создании эффективных цифровых решений. Наша цель – помогать бизнесам развиваться и достигать новых высот в онлайн-пространстве. <br />

                        Мы предлагаем полный спектр услуг: от UX/UI дизайна и разработки сайтов до SEO и SMM. Мы обладаем глубокими знаниями и опытом, что позволяет нам разрабатывать индивидуальные решения для каждого клиента. <br />

                        Каждый проект для нас – это возможность продемонстрировать наше мастерство и творческий подход. Мы уделяем внимание каждой детали, чтобы создать продукт, который не только удовлетворяет, но и превосходит ожидания. <br />

                        В Codus мы верим, что ключ к успеху – это тесное сотрудничество с клиентом, прозрачность и стремление к совершенству. Мы гордимся нашими работами и отзывами довольных клиентов, которые доверили нам свои проекты. <br />

                        Присоединяйтесь к нам, и мы поможем вашему бизнесу достичь новых вершин!

                    </div>

                    <Link to="/contacts">
                        <div className={s.InfoUsers_leftPart_button_Hire_us}>
                            Связаться с нами
                        </div>
                    </Link>
                </div>

                <div className={s.InfoUsers_rightPart}>
                    <div className={s.InfoUsers_rightPart_points1}>
                        <img src={points} alt="" />
                    </div>
                    <div className={s.InfoUsers_rightPart_points2}>
                        <img src={points2} alt="" />
                    </div>

                    <div className={s.InfoUsers_rightPart_Card}>
                        <div className={s.InfoUsers_rightPart_inCard_img}>
                            <img src={hackerPhoto} alt="" />
                        </div>
                        <div className={s.InfoUsers_rightPart_inCard_dataUser}>
                            <div className={s.InfoUsers_rightPart_inCard_name}>
                                Кирилл Халецкий
                            </div>
                            <div className={s.InfoUsers_rightPart_inCard_shortDesc}>
                                > full-Stack разработчик
                            </div>
                            <div className={s.InfoUsers_rightPart_inCard_Experience}>
                                <span className={s.GoldenColorText}>опыт </span> > 4 лет
                            </div>
                            <div className={s.InfoUsers_rightPart_inCard_Stack}>
                                <span className={s.GoldenColorText}>технологии </span> = HTML, CSS, JavaScript, Wordpress, git, php, React, NodeJS, ExpressJS, MongoDB, MariaDB, Python, C#, C++
                            </div>
                        </div>
                    </div>
                    <div className={s.InfoUsers_rightPart_Card}>
                        <div className={s.InfoUsers_rightPart_inCard_img}>
                            <img src={hackerPhoto} alt="" />
                        </div>
                        <div className={s.InfoUsers_rightPart_inCard_dataUser}>
                            <div className={s.InfoUsers_rightPart_inCard_name}>
                                Сергей Свинцов
                            </div>
                            <div className={s.InfoUsers_rightPart_inCard_shortDesc}>
                                > ui/ux дизайнер
                            </div>
                            <div className={s.InfoUsers_rightPart_inCard_Experience}>
                                <span className={s.GoldenColorText}>опыт</span> > 3 лет
                            </div>
                            <div className={s.InfoUsers_rightPart_inCard_Stack}>
                                <span className={s.GoldenColorText}>технологии</span> = веб, приложения, мобильные приложения, grids, ui-kit, дизайн-системы, CJM, user story, job story, JTBD
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default InfoUsers