import s from './Projects.module.css'
import Project_Card from './../../../../images/Project_Card.png'
import React, { useState, useEffect } from 'react';
import Card from '../../Projects/Card/Card';
import { Link, useNavigate } from 'react-router-dom';
const Projects = () => {
    const navigate = useNavigate()
    const [projects, setProjects] = useState(null);

    const viewAllProjectsNav = () =>{
        navigate(`/projects`)
    }

    const callAllProjects = async () => {
        try {
            const response = await fetch('/api/projects/all/main');
            if (!response.ok) {
                throw Error('Failed to fetch data');
            }
            const data = await response.json();
            data.map(el => console.log(el))
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return { error: error.message };
        }
    }


    useEffect(() => {
        callAllProjects()
            .then(res => setProjects(res))
            .catch(err => console.error(err));
    }, []);


    return (<div className={s.project}>
        <div className={s.project_title}>
            <div className={s.project_title_text}>
                <div><span className={s.pinkColor}>/</span>Проекты</div>
                <div className={s.project_title_line}></div>
                <Link to="/projects"><div className={s.project_title_btnViewAll} onClick={viewAllProjectsNav}>
                    Просмотреть все
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M10 17L15 12L10 7V17Z" fill="#FFA114" />
                    </svg>
                </div>
                </Link>
            </div>
        </div>

        <div className={s.Projects_Card}>
        
            {projects ? (
                projects.map(project => (
                    <Card key={project.id} project={project} />
                ))
            ) : (
                <div>Loading...</div>
            )}




        </div>

    </div>
    )

}

export default Projects