import Header from '../../Header/Header'
import s from './HomePage.module.css'
import InfoUsers from './InfoUsers/InfoUsers'
import Projects from './Projects/Projects'
import About from './About/About'
import Skills from './Skills/Skills'
import WhatYouGet from './WhatYouGet/WhatYouGet'
import Contact from '../../Contact/Contact'
import { Helmet } from 'react-helmet';

const HomePage = () => {
    return (
        <div className={s.HomePage}>
            <Helmet>
                <title>Codus | Кто мы такие?</title>
                <meta name="description" content="Мы команда профессионалов всфере разработки сайтов и веб-приложений. Готовы предложить не только красивые сайты, но и действительно рабочие решения для вашего бизнеса. Наша команда состоит действительно из профессионалов своего дела. Мы ответсвенны за то, что мы делаем!" />
                {/* Другие мета-теги */}
            </Helmet>

            <InfoUsers />
            <Projects />
            {/* <Skills /> */}
            <About />
            <WhatYouGet />
            <Contact />
        </div>
    )
}

export default HomePage