import s from './WhatYouGet.module.css'

const WhatYouGet = () => {
    return (
        <div className={s.whatYouGet_Area}>

            <div className={s.whatYouGet_Area_title}>
                <span className={s.pinkColor}>/</span> процесс работы
                <div className={s.pinkLine}></div>
            </div>

            <div className={s.WhatYouGet_table_area}>
                <div className={s.whatYouGet_table}>
                    <div className={s.whatYouGet_Area_table_title}>#1 Предпроектное исследование</div>
                    <div className={s.whatYouGet_table_desc}>
                    Аудитория, конкуренты, интеграция
                    </div>
                </div>
                    
                <div className={s.whatYouGet_table}>
                    <div className={s.whatYouGet_Area_table_title}>#2 Проектирование</div>
                    <div className={s.whatYouGet_table_desc}>
                    Создаём прототипы интерфейсов, продумываем структуру каталога, прорабатываем интеграции
                    </div>
                </div>

                <div className={s.whatYouGet_table}>
                    <div className={s.whatYouGet_Area_table_title}>#3 Дизайн</div>
                    <div className={s.whatYouGet_table_desc}>
                    Рисуем все необходимые страницы и интерфейсы в соответствии с Вашим фирменным стилем
                    </div>
                </div>

                <div className={s.whatYouGet_table}>
                    <div className={s.whatYouGet_Area_table_title}>#4 Разработка</div>
                    <div className={s.whatYouGet_table_desc}>
                    Адаптивная вёрстка, программирование
                    </div>
                </div>

                <div className={s.whatYouGet_table}>
                    <div className={s.whatYouGet_Area_table_title}>#5 Тестирование</div>
                    <div className={s.whatYouGet_table_desc}>
                    Проверка, наполнение контентом, настройка аналитики, обучение
                    </div>
                </div>

                <div className={s.whatYouGet_table}>
                    <div className={s.whatYouGet_Area_table_title}>#6 Запуск</div>
                    <div className={s.whatYouGet_table_desc}>
                    Ваш сайт готов продавать
                    </div>
                </div>

                
            </div>

            
        </div>
    )
}

export default WhatYouGet
