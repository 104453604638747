import s from './ProjectPage.module.css'
import ContactForm from '../../Contact/ContactForm/ContactForm'
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Image } from "antd";
import { Helmet } from 'react-helmet';

const ProjectPage = () => {

    const { id } = useParams(); // Получаем projectId из URL

    const [project, setProject] = useState(null);

    const callProject = async (projectId) => {
        try {
            const response = await fetch(`/api/projects/${projectId}`);
            if (!response.ok) {
                throw Error('Failed to fetch data');
            }
            const data = await response.json();
            data.map(el => console.log(el))
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return { error: error.message };
        }
    }


    useEffect(() => {
        if (id) {
            callProject(id)
                .then(res => setProject(res))
                .catch(err => console.error(err));
        }
    }, [id]);

    if (!project) {
        return <div>загрузка...</div>;
    }



    return (
        <div className={s.ProjectPageArea}>
            <Helmet>
                <title>{project[0].name} | Разработка сайтов любой сложности</title>
                <meta name="description" content="Мы также работаем с компаниями сообща и создаем совместные решения для разных направлений бизнеса. Создаем не только рабочие, но и красивые сайты на заказ." />
                
            </Helmet>


            <div className={s.headerPage}>
                <div className={s.titleProjectName}><span className={s.pinkColor}>/</span>{project[0].name}</div>
                <div className={s.ProjectPageDesc}>{project[0].desc}</div>

                <div className={s.areaTechno}>
                    <div className={s.itemTech}>
                        <div className={s.type}>разработка: </div>
                        <div className={s.textType}>{project[0].techdev}</div>
                    </div>
                    <div className={s.itemTech}>
                        <div className={s.type}>дизайн: </div>
                        <div className={s.textType}>{project[0].techdes}</div>
                    </div>
                </div>
            </div>

            <div className={s.contentPage}>
                <div className={s.titlePreview}><span className={s.pinkColor}>#</span>предпросмотр</div>
                <div className={s.mainContent}>
                    <div className={s.itemContent}>
                        {project[0].images.map((image, index) => (

                            <img key={index} src={`https://codus-web.ru/` + image} alt={`Project image ${index + 1}`} />
                        ))}

                    </div>

                </div>
            </div>

            <div className={s.mainAreaContact}>
                <div className={s.titlePreview}><span className={s.pinkColor}>/</span>связаться с нами</div>
                <ContactForm />
            </div>
        </div>

    )
}
export default ProjectPage