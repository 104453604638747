import { Link } from "react-router-dom";
import s from './Cards.module.css'
const Cards = ({ name, price, desc, mark }) => {
    return (
        <div className={s.Card}>
            <div className={s.inCard}>
                <div className={s.mainCard}>
                    <div className={s.titleCard}>{name}</div>
                    <div className={s.priceCard}>{price}</div>
                    <div className={s.descCard}>
                        {desc.map((item) => {
                            return <li>{item}</li>;
                        })}
                    </div>
                </div>
                {mark ? (<div className={s.mark}><span className={s.pinkColor}>*</span>{mark}</div>) : ''}
                
                <Link to="/contacts"> <div className={s.btnSend}>Связаться с нами</div></Link>
            </div>
        </div>


    )
}

export default Cards