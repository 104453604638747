import { Link } from "react-router-dom";
import Card from "../Card/Card"
import s from './TypeDev.module.css'
import React, { useState, useEffect } from 'react';


const TypeDev = ({ type, title }) => {


    const [projects, setProjects] = useState(null);

    const callProjects = async (type) => {
        try {
            const response = await fetch(`/api/projects/${type}`);
            if (!response.ok) {
                throw Error('Failed to fetch data');
            }
            const data = await response.json();
            data.map(el => console.log(el))
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return { error: error.message };
        }
    }


    let linktype = 'allprojects'
    if (type == 'design') {
        linktype = 'alldesign'
    }
    if (type == 'dev') {
        linktype = 'alldev'
    }
    useEffect(() => {
        callProjects(type)
            .then(res => setProjects(res))
            .catch(err => console.error(err));
    }, []);
    console.log(linktype)
    return (
        <div className={s.Projects_Items}>
            <div className={s.Projects_Items_type}>
                <div className={s.Projects_Items_type_name}>
                    <span className={s.pinkColor}>#</span>{title}
                </div>

                <Link to={'/projects/' + linktype}>
                    <div className={s.Projects_Items_type_view}>посмотреть все
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 17L15 12L10 7V17Z" fill="#FFA114" />
                        </svg>
                    </div>
                </Link>
            </div>

            <div className={s.Projects_Items_Cards}>

                {projects ? (
                    projects.map(project => (
                        <Card key={project.id} project={project} />
                    ))
                ) : (
                    <div>Загрузка...</div>
                )}

            </div>
        </div>
    )
}

export default TypeDev