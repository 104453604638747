import { useState } from 'react';
import s from './ContactForm.module.css';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [telegram, setTelegram] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [about, setAbout] = useState('');

    const handleItemsContact = async (e) => {
        e.preventDefault();

        const data = {
            name,
            telegram,
            email,
            title,
            about,
        };

        console.log(data);

        try {
            const response = await fetch('https://codus-web.ru/send-message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                alert('Message sent successfully!');
            } else {
                alert('Failed to send message.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Error sending message.');
        }
    };

    return (
        <div className={s.Contact_form}>
            <div className={s.Contact_form_area}>
                <div className={s.Contact_form_area_title}>Расскажите о вашем проекте</div>

                <form className={s.Contact_form_area_mainForm} onSubmit={handleItemsContact}>
                    <input className={s.Contact_form_input} type="text" placeholder='Имя' onChange={(e) => setName(e.target.value)} />
                    <div className={s.Contact_form_area_mainForm_twoInput}>
                        <input className={s.Contact_form_input} type="text" placeholder='Telegram' onChange={(e) => setTelegram(e.target.value)} />
                        <input className={s.Contact_form_input} type="text" placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <input className={s.Contact_form_input} type="text" placeholder='Название проекта' onChange={(e) => setTitle(e.target.value)} />
                    <textarea className={s.Contact_form_input} placeholder='О проекте' onChange={(e) => setAbout(e.target.value)}></textarea>
                    <button type='submit' className={s.btn_send_email}>Отправить</button>
                </form>
            </div>
        </div>
    );
}

export default ContactForm;
