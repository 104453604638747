import { useEffect, useRef, useState } from 'react';
import s from './Header.module.css'
import { Link } from "react-router-dom";
const Header = () => {
    const refSelectorLang = useRef()
    const [lang, setLang] = useState('en')
    const onChangeSelector = () => setLang(refSelectorLang.current.value)

    const [codusText, SetCodusText] = useState('Codus')
    const [burgerOpen, setBurgerOpen] = useState(false);

    const handleBurgerIcon = () => {
        setBurgerOpen(prevState => !prevState);
    }


    useEffect(() => {
        console.log('Переключаем язык сайта ', lang)
    }, [lang])


    return (
        <>
            <div className={s.header}>
            <Link to="/"> <div className={s.header_logo}>
                    <div className={s.header_logo_text}><span className={s.header_menu_item_sharp}>{'{'}</span>{codusText}<span className={s.header_menu_item_sharp}>{'}'}</span></div>
                </div> </Link>

                <div className={s.header_rightPart}>
                    <div className={s.header_menu}>
                        <div className={s.header_menu_item}>
                            <Link to="/"> <span className={s.header_menu_item_sharp}>#</span>главная </Link>
                        </div>
                        <div className={s.header_menu_item}>
                            <Link to="/about"><span className={s.header_menu_item_sharp}>#</span>о нас </Link>
                        </div>
                        <div className={s.header_menu_item}>
                            <Link to="/projects"><span className={s.header_menu_item_sharp}>#</span>проекты </Link>
                        </div>
                        <div className={s.header_menu_item}>
                            <Link to="/contacts"> <span className={s.header_menu_item_sharp}>#</span>контакты </Link>
                        </div>
                    </div>

                    {/* <div className={s.header_selectorLang}>
                        <select ref={refSelectorLang} className={s.header_selector} onChange={onChangeSelector} name="" id="">
                            <option value="ru">RU</option>
                            <option value="en">EN</option>
                        </select>
                    </div> */}

                    <Link to="/contacts">
                        <div className={s.header_contactBtn}>
                            Связаться с нами
                        </div>
                    </Link>
                </div>

            </div>


            <div className={s.headerMob}>
            <Link to="/"> <div className={s.header_logo}>
                    <div className={s.header_logo_text}><span className={s.header_menu_logo_sharp}>{'{'}</span>{codusText}<span className={s.header_menu_logo_sharp}>{'}'}</span></div>
                </div> </Link>

                <div className={s.burgerMenuBtn}>
                    <div className={s.iconBurger} onClick={handleBurgerIcon}>
                        {burgerOpen ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18 6L6 18" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6 6L18 18" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect y="5" width="24" height="2" fill="#D9D9D9" />
                                <rect x="9" y="12" width="15" height="2" fill="#D9D9D9" />
                            </svg>
                        )}

                    </div>
                </div>

                <div className={`${s.mobileMenu} ${burgerOpen ? s.openBurger : ''}`}>
                    <div className={s.itemsMenu}>



                        <Link to="/"><div className={s.menuItemMob} onClick={handleBurgerIcon}><span className={s.header_menu_item_sharp}>#</span>главная</div></Link>
                        <Link to="/about"><div className={s.menuItemMob} onClick={handleBurgerIcon}><span className={s.header_menu_item_sharp}>#</span>о нас</div></Link>
                        <Link to="/projects"><div className={s.menuItemMob} onClick={handleBurgerIcon}><span className={s.header_menu_item_sharp}>#</span>проекты</div></Link>
                        <Link to="/contacts"><div className={s.menuItemMob} onClick={handleBurgerIcon}><span className={s.header_menu_item_sharp}>#</span>контакты</div></Link>

                        {/* <div className={s.header_selectorLang}>
                            <select ref={refSelectorLang} className={s.header_selector} onChange={onChangeSelector} name="" id="">
                                <option value="ru">RU</option>
                                <option value="en">EN</option>
                            </select>
                        </div> */}

                        <Link to="/contacts">
                            <div className={s.header_contactBtn}>
                                Связаться с нами
                            </div>
                        </Link>
                    </div>
                </div>
            </div >
        </>)
}

export default Header;
