import './App.css'

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

import HomePage from "./components/Pages/HomePage/HomePage.jsx";

import Contact from './components/Pages/Contact/Contact';
import Projects from './components/Pages/Projects/Projects';
import ProjectPage from './components/Pages/ProjectPage/ProjectPage'
import SingleProjects from './components/Pages/SingleProjects/SingleProjects'
import AddNewPortfolio from './components/Pages/AddNewPortfolio/AddNewPortfolio'
import SocialLinks from './components/SocialLinks/SolialLinks';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

import { Route, Routes } from "react-router-dom";
import { useState, useEffect } from 'react';
import Login from './components/Pages/Login/Login.jsx';
import MainPage from './components/Pages/MainPage/MainPage.jsx';



function App() {
  let design = 'design'
  let dev = 'dev'
  let full = 'full'
  return (
    <>

      {/* <SocialLinks /> */}
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/about" element={<HomePage />} />
        <Route path="/login" element={<Login />} />

        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/projects" element={<Projects />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/projects/allprojects" element={<SingleProjects type={full} title="полный цикл" />} />
        <Route path="/projects/alldesign" element={<SingleProjects type={design} title="дизайн" />} />
        <Route path="/projects/alldev" element={<SingleProjects type={dev} title="разработка" />} />
        <Route path="/projects/:id" element={<ProjectPage />} />
        <Route path="/addnewportfolio" element={
          <PrivateRoute>
            <AddNewPortfolio />
          </PrivateRoute>
        } />
        {/* <Route path="/express" element={express} /> */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
