import { Button, Form, Input, message } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';

const { Title } = Typography;

const Login = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await axios.post('/api/login', {
                username: values.login,
                password: values.pass,
            });
            message.success('Успешный вход!');
            localStorage.setItem('token', response.data.token);
            navigate('/addnewportfolio');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                message.error('Неверные учетные данные!');
            } else {
                message.error('Ошибка сервера!');
            }
        } finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Неуспешно:', errorInfo);
    };

    return (
        <div className="loginarea">
            <Title level={2}>Вход в систему</Title>
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Логин"
                    name="login"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите ваше имя пользователя!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Пароль"
                    name="pass"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите ваш пароль!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Login;
