
import Contact from "../../Contact/Contact"
import Projects from "../HomePage/Projects/Projects"
import FirstScreenImage from "./FirstScreenImage/FirstScreenImage"
import { Helmet } from 'react-helmet';
const MainPage = () => {
    return (
        <>
            <Helmet>
                <title>Codus | Разработка сайтов под ключ в Беларуси и России</title>
                <meta name="description" content="Разработка сайтов в Беларуси и России. Делаем сайты на заказ, увеличиваем конверсию сайтов, продвигаем сайт в поисковых системах. Ускоряем и оптимизируем сайт!" />
                {/* Другие мета-теги */}
            </Helmet>
            <FirstScreenImage />
            {/* <Projects /> */}
            <Contact />
        </>

    )
}
export default MainPage