import s from './Card.module.css'
import image from './../../../../images/Project_Card.png'
import { Route, Routes  } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Card = ({project}) => {

    const navigate = useNavigate();

    const handleReadPortfolio = () => {
        navigate(`/projects/${project.id}`)
    }

    return (
        <div className={s.Projects_Items_Cards_item}>
            <div className={s.Projects_Items_Cards_item_img}>
                <img src={`https://codus-web.ru/${project.image}`} alt="" />
            </div>
            <div className={s.Projects_Items_Cards_item_title}>
                <div className={s.title_card_desc}>
                    <div className={s.lineDesc}><span className={s.goldColor}>разработка:</span> <span className={s.textTech}>{project.techdev}</span> </div>
                    <div className={s.lineDesc}><span className={s.goldColor}>дизайн:</span> <span className={s.textTech}>{project.techdes}</span>  </div>
                </div>
            </div>

            <div className={s.Projects_Items_Cards_item_desc}>
                <div className={s.Projects_Items_Cards_item_desc_area}>
                    <div className={s.title_card_main}>{project.name}</div>
                    <div className={s.title_card_desc_main}>
                    {project.desc}
                    </div>
                </div>
            </div>

            <div className={s.Projects_Items_Cards_item_btns}>
                <div className={s.Projects_Items_Cards_item_btns_area}>
                    <div onClick={handleReadPortfolio} className={s.btnReadMore}>{'Подробнее'}</div>
                </div>
            </div>
        </div>

    )
}

export default Card