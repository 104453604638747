
import s from './Projects.module.css';
import TypeDev from './TypeDev/TypeDev';
import { Helmet } from 'react-helmet';

const Projects = () => {

    let design = 'design'
    let develop = 'dev'
    let full = 'full'



    return (
        <div className={s.Projects}>
            <Helmet>
                <title>Все сайты | Codus</title>
                <meta name="description" content="Разработка сайтов в Могилеве. Создаем сайты на заказ, ускоряем сайты, увеличиваем конверсию сайтов." />
                {/* Другие мета-теги */}
            </Helmet>
            <div className={s.Projects_title}>
                <div className={s.Projects_title_main}><span className={s.pinkColor}>/</span>проекты</div>
                <div className={s.Projects_title_desc}>список наших проектов</div>
            </div>
            <TypeDev type={full} title="полный цикл" />
            <TypeDev type={develop} title="разработка" />
            <TypeDev type={design} title="дизайн" />
        </div>
    );
}

export default Projects;
