import s from './Contact.module.css'
import ContactForm from './ContactForm/ContactForm'

const Contact = () => {
    return (
        <div className={s.Contact}>
            <div className={s.Contact_title}>
                <span className={s.pinkColor}>/</span>связаться с нами
                <div className={s.pinkCOlor}></div>
            </div>

            <ContactForm />
        </div>
    )
}

export default Contact