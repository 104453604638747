import ContactForm from '../../Contact/ContactForm/ContactForm';
import s from './Contact.module.css'
import { Helmet } from 'react-helmet';

const Contact = () => {
    return <div className={s.Contact}>
        <Helmet>
            <title>Codus | Как заказать сайт</title>
            <meta name="description" content="Написать нам, чтобы заказать сайт можно заполнив простую форму обратной связи. Как только придет от вас заявка, мы сразу же ее обработам и свяжемся с вами!" />
            {/* Другие мета-теги */}
        </Helmet>
        <div className={s.Contact_title}>
            <span className={s.Contact_slash}>/</span>контакты
        </div>

        <div className={s.area_Contacts_Blocks}>

            <div className={s.contact_block}>
                <div className={s.contact_text}>
                    Если вы заинтересованы в создании своего сайта и хотите развивать бизнес, то вы обратились по адресу. Наша команда готова исследовать и разработать для вас сайт, который добавит узнаваемости вашей компании и увеличит продажи!
                </div>

                <div className={s.socialIcons}>
                    <a href="https://t.me/Kirillkhaletsky" target='__blank'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="0.761719" y="0.355469" width="58.9804" height="58.9804" rx="29.4902" fill="#D1FF00" />
                            <path d="M29.3313 16.9434C21.7008 16.9434 15.5078 23.1363 15.5078 30.7669C15.5078 38.3975 21.7008 44.5904 29.3313 44.5904C36.9619 44.5904 43.1549 38.3975 43.1549 30.7669C43.1549 23.1363 36.9619 16.9434 29.3313 16.9434ZM35.7455 26.3434C35.5381 28.5275 34.6396 33.8357 34.1834 36.2825C33.9899 37.3192 33.6028 37.6648 33.2434 37.7063C32.4416 37.7754 31.8334 37.181 31.0593 36.6695C29.8428 35.8678 29.1516 35.3701 27.9766 34.596C26.6081 33.6975 27.4928 33.1998 28.2808 32.3981C28.4881 32.1907 32.0269 28.9698 32.096 28.6795C32.1056 28.6356 32.1044 28.5899 32.0923 28.5466C32.0803 28.5032 32.0578 28.4634 32.0269 28.4307C31.944 28.3616 31.8334 28.3892 31.7366 28.4031C31.6122 28.4307 29.6769 29.7163 25.9031 32.2598C25.3502 32.6331 24.8525 32.8266 24.4102 32.8128C23.9125 32.7989 22.9725 32.5363 22.2675 32.3013C21.3966 32.0248 20.7193 31.8728 20.7746 31.3889C20.8022 31.1401 21.1478 30.8913 21.7975 30.6287C25.834 28.8731 28.5158 27.7119 29.8566 27.1589C33.6996 25.5554 34.4875 25.2789 35.0128 25.2789C35.1234 25.2789 35.386 25.3066 35.5519 25.4448C35.6902 25.5554 35.7316 25.7075 35.7455 25.8181C35.7316 25.901 35.7593 26.1498 35.7455 26.3434Z" fill="#141414" />
                        </svg>
                    </a>

                    <a href="https://wa.me/+375297217850" target='__blank'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="0.171875" y="0.355469" width="58.9804" height="58.9804" rx="29.4902" fill="#D1FF00" />
                            <path d="M40.3798 19.1445C39.1061 17.8579 37.589 16.8378 35.9169 16.1437C34.2448 15.4496 32.4512 15.0953 30.6408 15.1016C23.0552 15.1016 16.8729 21.284 16.8729 28.8696C16.8729 31.3009 17.5119 33.6627 18.7067 35.7466L16.7617 42.8876L24.0555 40.9704C26.07 42.0679 28.3346 42.6515 30.6408 42.6515C38.2264 42.6515 44.4088 36.4691 44.4088 28.8835C44.4088 25.2019 42.9778 21.7425 40.3798 19.1445ZM30.6408 40.3174C28.5847 40.3174 26.5702 39.7617 24.8058 38.7197L24.389 38.4697L20.0544 39.6089L21.2075 35.3854L20.9296 34.9547C19.7873 33.1305 19.1807 31.022 19.1791 28.8696C19.1791 22.5622 24.3195 17.4218 30.6269 17.4218C33.6834 17.4218 36.5592 18.6166 38.7126 20.7839C39.7789 21.8453 40.6239 23.1077 41.1987 24.4981C41.7734 25.8884 42.0665 27.379 42.0609 28.8835C42.0886 35.1909 36.9482 40.3174 30.6408 40.3174ZM36.9205 31.7593C36.5731 31.5926 34.8782 30.759 34.5725 30.634C34.253 30.5229 34.0307 30.4673 33.7945 30.8007C33.5583 31.1481 32.9054 31.9261 32.7109 32.1483C32.5164 32.3845 32.308 32.4123 31.9607 32.2317C31.6133 32.065 30.5019 31.6899 29.1959 30.5229C28.1679 29.6059 27.4871 28.4806 27.2787 28.1333C27.0842 27.7859 27.2509 27.6053 27.4315 27.4247C27.5844 27.2719 27.7789 27.0218 27.9456 26.8273C28.1123 26.6328 28.1818 26.48 28.2929 26.2577C28.404 26.0215 28.3485 25.827 28.2651 25.6603C28.1818 25.4936 27.4871 23.7987 27.2092 23.104C26.9314 22.4371 26.6396 22.5205 26.4312 22.5066H25.7644C25.5282 22.5066 25.167 22.59 24.8474 22.9373C24.5418 23.2846 23.6526 24.1182 23.6526 25.8131C23.6526 27.5081 24.8891 29.1475 25.0558 29.3698C25.2226 29.6059 27.4871 33.0792 30.9326 34.5657C31.7523 34.9269 32.3913 35.1353 32.8915 35.2882C33.7112 35.5521 34.4614 35.5105 35.0588 35.4271C35.7257 35.3298 37.1011 34.5935 37.3789 33.7877C37.6707 32.9819 37.6707 32.3012 37.5734 32.1483C37.4762 31.9955 37.2678 31.9261 36.9205 31.7593Z" fill="#141414" />
                        </svg>
                    </a>
                </div>
            </div>

            <div className={s.contact_block}>
                <ContactForm />
            </div>

        </div>
    </div>
}
export default Contact;