import s from './AddNewPortfolio.module.css'
import axios from 'axios';
import { Button, Flex, Form, Input, Upload } from 'antd';
import { Typography, Select } from "antd";
import { useEffect, useState } from 'react';
const { Option } = Select;

const { Title } = Typography;





const AddNewPortfolio = () => {

    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [dev, setDev] = useState('');
    const [des, setDes] = useState('');
    const [type, setType] = useState('');
    const [images, setImages] = useState([])
    const [previewImage, setPreviewImage] = useState([]);

    const checker = useEffect(()=>{
        console.log(name)
    },[name])

    const handleSubmit = async (values) => {
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('desc', desc);
            formData.append('techdev', dev);
            formData.append('techdes', des);
            formData.append('status', type);

            // Добавляем файл превью изображения в formData
            if (previewImage.length > 0) {
                formData.append('previewImage', previewImage[0].originFileObj);
            }


            // Добавляем файлы изображений в formData
            images.forEach(file => {
                formData.append('images', file.originFileObj);
            });


            const response = await axios.post('/api/projects/add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Project added:', response.data);
        } catch (error) {
            console.error('Error adding project:', error);
        }
    };


    const normFile = (e) => {
        if (Array.isArray(e)) {
            return setImages(e);
        }
        return e?.fileList;
    };

    const result = useEffect(() => {
        console.log(name)
    }, [name])




    const [form] = Form.useForm();
    return (
        <div className="AddNewPortfolio">
            <Form
                form={form}
                scrollToFirstError
                style={{ paddingBlock: 12, width: 540 }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                onFinish={handleSubmit}
            >
                <Title style={{ color: '#ABB2BF' }}>Добавить новое портфолио</Title>

                <Title level={3} style={{ color: '#ABB2BF' }}>Добавить превью</Title>
                <Form.Item valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload
                        action=""
                        fileList={previewImage}
                        listType="picture-card"
                        onChange={({ fileList }) => setPreviewImage(fileList)}
                    >
                        {previewImage.length >= 1 ? null : (
                            <button style={{ border: 0, background: 'none', color: '#ABB2BF' }} type="button">
                                <div style={{ marginTop: 8 }}>Загрузить</div>
                            </button>
                        )}
                    </Upload>
                </Form.Item>

                <Title level={3} style={{ color: '#ABB2BF' }}>Название</Title>
                <Form.Item name="name" onChange={(e)=>setName(e.target.value)} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Title level={3} style={{ color: '#ABB2BF' }}>Описание проекта</Title>
                <Form.Item name="desc" onChange={(e)=>setDesc(e.target.value)}>
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Title level={3} style={{ color: '#ABB2BF' }}>Список технологий разработки</Title>
                <Form.Item name="dev" onChange={(e)=>setDev(e.target.value)}>
                    <Input />
                </Form.Item>
                <Title level={3} style={{ color: '#ABB2BF' }}>Список технологий дизайна</Title>
                <Form.Item name="des" onChange={(e)=>setDes(e.target.value)}>
                    <Input />
                </Form.Item>
                <Title level={3} style={{ color: '#ABB2BF' }}>Добавить фотки проекта</Title>
                <Form.Item valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload action="" fileList={images} listType="picture-card" onChange={({ fileList }) => setImages(fileList)}>
                        <button style={{ border: 0, background: 'none', color: '#ABB2BF' }} type="button">
                            <div style={{ marginTop: 8 }}>Загрузить</div>
                        </button>
                    </Upload>
                </Form.Item>

                <Form.Item>
                    <Select
                        placeholder="Выбери статус разработки"
                        onChange={setType}
                        allowClear
                    >
                        <Option value="full">Полный</Option>
                        <Option value="dev">Разработка</Option>
                        <Option value="des">Дизайн</Option>
                    </Select>
                </Form.Item>

                <Form.Item >
                    <Flex gap="small">
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button danger onClick={() => form.resetFields()}>
                            Reset
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddNewPortfolio