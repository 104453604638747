import s from './SingleProjects.module.css'
import Card from '../Projects/Card/Card'
import ContactForm from '../../Contact/ContactForm/ContactForm'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const SingleProjects = ({ type, title }) => {

    const [projects, setProjects] = useState(null);

    const callProjects = async (type) => {
        try {
            const response = await fetch(`/api/projects/${type}`);
            if (!response.ok) {
                throw Error('Failed to fetch data');
            }
            const data = await response.json();
            data.map(el => console.log(el))
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return { error: error.message };
        }
    }


    useEffect(() => {
        callProjects(type)
            .then(res => setProjects(res))
            .catch(err => console.error(err));
    }, []);


    return (

        <div className={s.SingleProjects}>
            <Helmet>
                <title>Codus | Разработка сайтов на заказ</title>
                <meta name="description" content="Создание сайтов любой сложности в Беларуси и России"  />
                {/* Другие мета-теги */}
            </Helmet>
            <div className={s.SingleProjects_title}>
                <div className={s.SingleProjects_title_main}><span className={s.pinkColor}>/</span>{title}</div>
            </div>

            <div className={s.areaCardsSP}>
                <div className={s.itemCards}>
                    {projects ? (
                        projects.map(project => (
                            <Card key={project.id} project={project} />
                        ))
                    ) : (
                        <div>Загрузка...</div>
                    )}
                </div>
            </div>

            <div className="contactArea">
                <div className="titleContact">
                    <div className={s.SingleProjects_title}>
                        <div className={s.SingleProjects_title_main}><span className={s.pinkColor}>/</span>связаться с нами</div>
                    </div>
                </div>
                <div className="mainAreaContact">
                    <ContactForm />
                </div>

            </div>
        </div>

    )
}

export default SingleProjects